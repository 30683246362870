<!--
 * @Descripttion: 网校账户
 -->
<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: 'AssetAdmin'
}
</script>
